import * as React from "react";
import { Media, Video } from '@vidstack/player-react';
const MovingWork = ({data}) => {
    return (
        <section id="movingwork" className="w-full bg-black px-[1.313rem] py-[3.938rem] md:py-[5.438rem] md:pl-[12rem]" data-midnight="white">
            <div className="max-w-screen-xl mx-auto px-[1.313rem] flex flex-wrap md:justify-between">
                <div className="w-full md:w-7/12">
                    <Media>
                        <Video loading="visible" poster={data.videoThumbnail.mediaItemUrl} controls preload="none" muted="true">
                            <video loading="visible" poster={data.videoThumbnail.mediaItemUrl} src={data.video.mediaItemUrl} preload="none" data-video="0" controls />
                        </Video>
                    </Media>
                </div>
                <div className="w-full md:w-4/12">
                    <div className="mt-[2.125rem] md:mt-0"><h1 className="uppercase text-white m-0" dangerouslySetInnerHTML={{__html: data.heading}}></h1></div>
                    <div className="mt-[0.688rem] md:mt-[2.125rem]"><h3 className="uppercase text-terracotta" dangerouslySetInnerHTML={{__html: data.subheading}}></h3></div>
                </div>
            </div>
        </section>
    )
}

export default MovingWork;