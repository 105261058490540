import * as React from "react";
import imgRight from "../../images/arrow-right.svg";
import imgLeft from "../../images/arrow-left.svg";
const Partnership = ({ data }) => {
    return (
        <section id="partnership" className="w-full bg-white overflow-hidden relative" data-midnight="default">
            <div id="desktoppartnershipgap" className="hidden md:block bg-black bg-neogreen bg-terracotta bg-aqua"></div>
            <div className="hidden md:block absolute top-0 left-0 bg-white h-full w-[13.75rem] z-10"></div>
            <div id="partnershipitems" className="w-full max-w-screen-xl mx-auto px-[1.313rem] pt-[3.813rem] md:pt-[3.688rem md:pl-[12rem] flex justify-stretch cursor-arrows2">
                {data.map((testimonial, i) => {
                    return (<div className={`self-stretch shrink-0 w-[18.313rem] md:w-[28.313rem] pt-[2.25rem] md:pt-[2.375rem] pb-[3.438rem] md:pb-[1.875rem] pl-[1.25rem] md:pl-[1.875rem] pr-[1.125rem] md:pr-[2.313rem] mr-[1.625rem] md:mr-[1.5rem] bg-${testimonial.backgroundColor.toLowerCase()}` } key={i.toString()}>
                        <div>
                        <h4 className={`${testimonial.backgroundColor.toLowerCase() === "neogreen" ? `text-black` : `text-white`}` } dangerouslySetInnerHTML={{__html: testimonial.quote}}></h4>
                    </div>
                    <div className={`mt-[1.563rem] md:mt-[2.25rem] testimonial-author ${testimonial.backgroundColor.toLowerCase() === "neogreen" ? `text-black` : `text-white`}` }>
                        {testimonial.author}
                    </div>
                    </div>)
                })}
            </div>
            <div className="my-10 px-[1.313rem] md:pl-[14rem] flex items-center">
                <img id="prevpartnership" src={imgLeft} alt="" className="mr-5 cursor-pointer m-0" style={{ opacity: 0.5, height: "32px" }} />
                <img id="nextpartnership" src={imgRight} alt="" className="cursor-pointer m-0" style={{ height: "32px" }} />
            </div>
        </section>
    )
}

export default Partnership;