import * as React from "react";
import logo from "../../images/propellogo3.svg";
import logowhite from "../../images/propelwhite.svg";
import linkedinLogo from "../../images/footer/linkedin.svg";
import instaLogo from "../../images/footer/insta.svg";
import closeIcon from "../../images/closemobimenu.svg";
import { Formik, Form, Field, ErrorMessage } from 'formik';
const Nav = () => {
    const submitHandler = (values) => {
        /* Then create a new FormData obj */
        let formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("phone", values.phone);
        formData.append("message", values.message);

        fetch(
            'https://propelhealthforward.com/qwe23/sd2s.php',
            {
                method: 'POST',
                mode: "no-cors",
                headers: {
                    'Content-Type': 'form-data',
                    'Accept': '*/*'
                },
                body: formData
            },
        ).then((rs) => console.log("msg", rs)).catch((er) => console.log("er", er));


    };
    return (
        <section id="menuscontainers" className="hidden w-full h-full overflow-hidden">
            <div id="coverblackdesk" className="hidden fixed top-0 left-0 bg-black h-full w-[13.75rem] z-50"></div>
            <div id="navdesklogo" className="hidden md:block w-[13.75rem]">
                <div className="midnightHeader default">
                    <button aria-label="PHC">
                        <img src={logo} alt="Propel Health Communications Logo" className="m-0 w-[11.098rem]" />
                    </button>
                </div>
                <div className="midnightHeader white">
                    <button aria-label="PHC">
                        <img src={logowhite} alt="Propel Health Communications Logo" className="m-0 w-[11.098rem]" />
                    </button>
                </div>
            </div>
            <div id="navdeskmenu" className="hidden md:block w-[13.75rem]">
                <div className="midnightHeader default">
                    <ul>
                        <li><a href="#top" className="current">WHAT WE DO</a></li>
                        <li><a className="howwedoitlink" href="#howwedoit">HOW WE DO IT</a></li>
                        <li><a href="#partnership">PARTNERSHIP</a></li>
                        <li className="sp"><a href="#movingwork">WORK</a></li>
                        <li><a href="#leadership">LEADERSHIP</a></li>
                        <li><a href="#contact">CONTACT</a></li>
                    </ul>
                </div>
                <div className="midnightHeader white">
                    <ul>
                        <li><a href="#top" className="current">WHAT WE DO</a></li>
                        <li><a href="#howwedoit">HOW WE DO IT</a></li>
                        <li><a href="#partnership">PARTNERSHIP</a></li>
                        <li className="sp"><a href="#movingwork">WORK</a></li>
                        <li><a href="#leadership">LEADERSHIP</a></li>
                        <li><a href="#contact">CONTACT</a></li>
                    </ul>
                </div>
            </div>
            <div id="navdeskmobile" className="md:hidden cursor-pointer">
                <div className="midnightHeader default mn">
                    MENU
                </div>
                <div className="midnightHeader white mn">
                    MENU
                </div>
            </div>
            <div id="navmobi" className="fixed top-0 left-0 w-full h-full overflow-x-hidden bg-black z-[60] pt-[2rem] pl-[1.313rem] pb-[1.125rem] pr-[3.875rem]">
                <div>
                    <img src={logowhite} alt="Propel Health Communications Logo" className="w-[10.313rem]" />
                </div>
                <div id="closemobi" className="absolute top-[2rem] right-0 cursor-pointer">
                    <img src={closeIcon} alt="Close Menu Icon" />
                </div>
                <div className="mt-[2.188rem]">
                    <ul className="flex w-full flex-wrap m-0 justify-between mobilelinks">
                        <li className="flex w-5/12"><a href="#top">WHAT WE DO</a></li>
                        <li className="flex w-5/12"><a href="#movingwork">WORK</a></li>
                        <li className="flex w-5/12"><a href="#howwedoit">HOW WE DO IT</a></li>
                        <li className="flex w-5/12"><a href="#leadership">LEADERSHIP</a></li>
                        <li className="flex w-5/12"><a href="#partnership">PARTNERSHIP</a></li>
                        <li className="flex w-5/12"><a href="#contact">CONTACT</a></li>
                    </ul>
                </div>
                <div className="mt-[3.313rem]">
                    <div><h3 className="text-white">Let’s get the ball rolling</h3></div>
                    <div id="contactForm2" className="form">
                        <Formik
                            initialValues={{ name: "", email: "", phone: "", message: "" }}
                            validate={(values) => {
                                const errors = {};
                                if (!values.name) {
                                    errors.name = "Required";
                                }

                                if (!values.email) {
                                    errors.email = "Required";
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = "Invalid email address";
                                }
                                if (!values.message) {
                                    errors.message = "Required";
                                }
                                if (values.phone !== "") {
                                    var phoneno = /^\d+$/;
                                    var phone = values.phone.match(phoneno);
                                    if (!phone) {
                                        errors.phone = "Invalid phone number";
                                    }
                                }

                                return errors;
                            }}

                            onSubmit={(values, { resetForm }) => {
                                submitHandler(values);
                                resetForm();

                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <div>
                                        <Field
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            className="form-contact-mobile-input"
                                        />
                                    </div>
                                    <ErrorMessage name="name" component="div" className="label-contact-mobile-input" />
                                    <div className="mt-[1.438rem]">
                                        <Field
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            className="form-contact-mobile-input"
                                        />
                                    </div>
                                    <ErrorMessage name="email" component="div" className="label-contact-mobile-input" />
                                    <div className="mt-[1.438rem]">
                                        <Field
                                            type="text"
                                            name="phone"
                                            placeholder="Phone Number"
                                            className="form-contact-mobile-input"
                                        />
                                    </div>
                                    <ErrorMessage name="phone" component="div" className="label-contact-mobile-input" />
                                    <div className="mt-[1.438rem]">
                                        <div className="text-left w-full">
                                            <label htmlFor="messageinput" className="label-contact-mobile-input">
                                                Message
                                            </label>
                                        </div>
                                        <Field
                                            component="textarea"
                                            name="message"
                                            className="mt-[1rem] border-2 form-contact-mobile-input txtarea"
                                        />
                                    </div>
                                    <ErrorMessage name="message" component="div" className="label-contact-mobile-input" />
                                    <div className="mt-[0.563rem] text-left flex">
                                        <button type="submit" disabled={isSubmitting} className="button-contact-mobile no-arrow cp cursor-pointer">
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <div className="mt-[2.813rem]">
                    <div className="menu uppercase">FOLLOW US</div>
                    <div className="mt-[0.938rem] md:mt-[1.375rem] flex">
                        <div>
                            <a href="https://www.linkedin.com/company/propelhealthcommunications" target="_blank" rel="noreferrer" aria-label="LinkedIn Profile"><img src={linkedinLogo} alt="" className="max-w-[1.5rem] m-0" /></a>
                        </div>
                        <div className="ml-[0.688rem]">
                            <a href="https://www.instagram.com/propelhealthforward/" target="_blank" rel="noreferrer" aria-label="Instagram Profile"><img src={instaLogo} alt="" className="max-w-[1.5rem] m-0" /></a>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Nav;