import * as React from "react";
const HeroBanner = ({ data }) => {
    return (
        <section id="whatwedo" data-midnight="default">
            <div className="w-full max-w-screen-xl mx-auto relative pl-[1.313rem] pr-[2.563rem] xl:px-0">
            <div className="pt-[6.5rem] md:pt-[6.563rem]">
                <div className="pl-[1rem] md:pl-0 text-right sm:w-[36.063rem] sm:text-left md:text-right lg:w-[53.125rem] xl:w-[62.5rem] xl:text-left lg:ml-auto lg:mr-0 sm:ml-auto sm:mr-0">
                    <h1 className="uppercase">{data.headline} <span id="rotatingword"></span></h1>
                </div>
            </div>
            </div>
            <div className="pt-[2.938rem] md:pt-[3.75rem] w-full pb-[3rem] md:pb-[9.375rem]">
                <video preload='auto' autoPlay muted playsInline className="w-full hidden md:block">
                    <source src={data.desktopVideo.mediaItemUrl} type="video/mp4" />
                </video>
                <video preload='auto' autoPlay muted playsInline className="w-full md:hidden">
                    <source src={data.mobileVideo.mediaItemUrl} type="video/mp4" />
                </video>
            </div>
            {data.rotatingWords.map((word, i) => {
                return (<div className="word-hero hidden" key={i.toString()}>{word.word}</div>)
            })}
        </section>
    )
}

export default HeroBanner;