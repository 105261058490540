import { useStaticQuery, graphql } from "gatsby"

export const useHomeQuery = () => {


    const data = useStaticQuery(graphql`
    query HomeQuery {
        wpPage(title: {eq: "HOME"}) {
            ACF_Homepage {
                hero {
                    desktopVideo {
                        mediaItemUrl
                    }
                    mobileVideo {
                        mediaItemUrl
                    }
                    headline
                    rotatingWords {
                        word
                    }
                }
                subheadings {
                    firstHeading
                    secondHeading
                }
                connectTheDots {
                    desktopVideo {
                        mediaItemUrl
                    }
                    mobileVideo {
                        mediaItemUrl
                    }
                    heading
                    subheading
                    copy
                    firstLine
                    secondLine
                    thirdLine
                    fourthLine
                    fifthLine
                    sixthLine
                    seventhLine
                    eightLine
                }
                howWeRoll{
                    howWeRollVideoDesktop {
                        mediaItemUrl
                    }
                    howWeRollVideoMobile {
                        mediaItemUrl
                    }
                    intentionalSection{
                        desktopVideo {
                            mediaItemUrl
                        }
                        mobileVideo {
                            mediaItemUrl
                        }
                        title
                        copy
                    }
                    neverStartSection{
                        desktopVideo {
                            mediaItemUrl
                        }
                        mobileVideo {
                            mediaItemUrl
                        }
                        title
                        copy
                    }
                    weMeetCustomersSection{
                        desktopVideo {
                            mediaItemUrl
                        }
                        mobileVideo {
                            mediaItemUrl
                        }
                        title
                        copy
                    }
                    tectionSection{
                        desktopVideo {
                            mediaItemUrl
                        }
                        mobileVideo {
                            mediaItemUrl
                        }
                        title
                        copy
                    }
                }
                testimonials{
                    quote
                    author
                    backgroundColor
                }
                movingWork{
                    video{
                        mediaItemUrl
                    }
                    videoThumbnail{
                        mediaItemUrl
                    }
                    heading
                    subheading
                }
                leadership {
                    photo {
                        mediaItemUrl
                    }
                    title
                    linkedInUrl
                }
                contact {
                    desktopVideo {
                        mediaItemUrl
                    }
                    mobileVideo {
                        mediaItemUrl
                    }
                    title
                    thankYouMessage
                }
                footer {
                    desktopVideo {
                        mediaItemUrl
                    }
                    mobileVideo {
                        mediaItemUrl
                    }
                    copy
                    copyright
                    links {
                        label
                        url
                    }
                }
            }
        }
    }     
    `)
    return data.wpPage;
}