import React, { useEffect } from "react";
import HeroBanner from "../components/home/herobanner";
import Authentic from "../components/home/authentic";
import ConnectTheDots from "../components/home/connectthedots";
import MovingWork from "../components/home/movingwork";
import Partnership from "../components/home/partnership";
import Leadership from "../components/home/leadership";
import HowWeDoIt from "../components/home/howwedoit";
import Contact from "../components/home/contact";
import Footer from "../components/home/footer";
import Nav from "../components/home/nav";
import { useHomeQuery } from "../hooks/useHomeQuery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const IndexPage = () => {

  useEffect(() => {
    const mainJsTag2 = document.createElement('script')
    // given the js/main.js file is put in the static folder
    mainJsTag2.src = '/js/jquery.ui.touch-punch.min.js'
    document.body.appendChild(mainJsTag2);
    const mainJsTag = document.createElement('script')
    // given the js/main.js file is put in the static folder
    mainJsTag.src = '/js/app.js'
    document.body.appendChild(mainJsTag);
    //ConnectTheDots

    //responsive
    let mm = gsap.matchMedia();

    // mm.add("(min-width: 768px)", () => {
      //Connect the dots
      // const dotsgraphic = document.querySelector("#dotsgraphic2");
      // const mHeight = dotsgraphic.scrollHeight;
      // gsap.timeline({
      //   ease: "none",
      //   scrollTrigger: {
      //     trigger: '#connectthedots',
      //     start: 'top top',
      //     end: "+=" + (mHeight * 5),
      //     pin: true,
      //     scrub: true,
      //     pinSpacing: true,
      //     // once: true,
      //   }
      // }).fromTo(
      //   '#dotsgraphic2',
      //   {
      //     height: '0%'
      //   },
      //   {
      //     height: '100%',
      //     duration: 12
      //   }
      // );

      //HowWeRoll
    //   const howWeRollContainer = document.querySelector("#howwedoititems");

    //   function getScrollAmount() {
    //     let howWeRollWidth = howWeRollContainer.scrollWidth;
    //     return -(howWeRollWidth - window.innerWidth);
    //   }

    //   const tween = gsap.to(howWeRollContainer, {
    //     x: getScrollAmount,
    //     duration: 6,
    //     ease: "none",
    //   });

    //   ScrollTrigger.create({
    //     trigger: "#howwedoit",
    //     start: "top 0%",
    //     end: () => `+=${getScrollAmount() * -1}`,
    //     pin: true,
    //     animation: tween,
    //     scrub: 1,
    //     invalidateOnRefresh: true,
    //   })
    // });


    // mm.add("(max-width: 767px)", () => {

    //   //HowWeRoll
    //   const howWeRollContainer = document.querySelector("#howwedoititems");

    //   function getScrollAmount() {
    //     let howWeRollWidth = howWeRollContainer.scrollWidth;
    //     return -(howWeRollWidth - window.innerWidth);
    //   }

    //   const tween = gsap.to(howWeRollContainer, {
    //     x: getScrollAmount,
    //     duration: 6,
    //     ease: "none",
    //   });
   
    //   ScrollTrigger.create({
    //     trigger: "#howwedoit",
    //     start: "top 0%",
    //     end: () => `+=${getScrollAmount() * -1}`,
    //     pin: true,
    //     animation: tween,
    //     scrub: 1,
    //     invalidateOnRefresh: true,
    //     onUpdate: (self) => {
          
          
    //     },
    //   });

    //   window.addEventListener("scroll", function(){

    //     var hwdi = document.getElementById("howwedoit");
    //     var pos =  hwdi.offsetHeight;
        
        
    //     //   var tf = document.getElementById("howwedoititems");
    //     //   var spacer = document.getElementById("spacer");
    //     //   console.log("scroll", window.scrollY);

    //     //   // // translate3d(-1789px, 0px, 0px);
    //       if (window.scrollY >= 1824 && window.scrollY <= 4305){
    //         setFix()
    //       } else {
    //         unsetFix();
    //       }

         
         
          
    //       function setFix(){
    //         var cp = document.getElementById("mweas");
    //         var partnership = document.getElementById("partnership");
    //         partnership.style.opacity = 0;
    //         if (cp === null){
    //           cp = document.getElementById("partnership").cloneNode(true);
    //           cp.setAttribute("id", "mweas");
    //           document.body.appendChild(cp);
    //         }
    //         cp.style.opacity = 1;
    //         cp.style.position = "fixed";
    //         cp.style.top= pos+"px";
    //         cp.style.zIndex = "-1";
         
    //       }

    //       function unsetFix(){
    //         var partnership = document.getElementById("partnership");
    //         partnership.style.opacity = 1;
    //         var cp = document.getElementById("mweas");
    //         if (cp !== null){
    //           cp.remove();
    //         }
    //       }
    //   });
      
    // });



    let links = gsap.utils.toArray("ul li a");
    links.forEach(a => {
      let element = document.querySelector(a.getAttribute("href")),
        linkST = ScrollTrigger.create({
          trigger: element,
          start: "top top"
        });
      ScrollTrigger.create({
        trigger: element,
        start: "top top",
        end: "bottom bottom",
        onToggle: self => self.isActive && setActive(a)
      });
      a.addEventListener("click", e => {
        e.preventDefault();
        gsap.to(window, { duration: 1, scrollTo: linkST.start, overwrite: "auto" });
      });
    });

    let logos = document.querySelectorAll("#navdesklogo button")
    logos.forEach(item => {
      item.addEventListener('click', function () {
        var els = document.querySelectorAll("a[href='#top']");
        els[0].click();
      });
    });

    function setActive(link) {
      links.forEach(el => el.classList.remove("current"));
      link.classList.add("current");
    }

    var hash = window.location.hash;
    if (hash !== "") {
      var els = document.querySelectorAll("a[href='" + hash + "']");
      if (els.length > 1) {
        els[0].click();
      }
    }

  });
  const data = useHomeQuery();
  return (
    <>
      <Nav />
      <div id="top">
        <HeroBanner data={data.ACF_Homepage.hero} />
        <Authentic data={data.ACF_Homepage.subheadings} />
        <ConnectTheDots data={data.ACF_Homepage.connectTheDots} />
      </div>
      <HowWeDoIt data={data.ACF_Homepage.howWeRoll} />
      <Partnership data={data.ACF_Homepage.testimonials} />
      <MovingWork data={data.ACF_Homepage.movingWork} />
      <Leadership data={data.ACF_Homepage.leadership} />
      <Contact data={data.ACF_Homepage.contact} />
      <Footer data={data.ACF_Homepage.footer} />
    </>
  )
}

export default IndexPage;

export const Head = () => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/unm6epi.css" />
    </>
  )
}