import * as React from "react";
import OvalGraphic from "../../images/connectthedots/Group2.png";
const ConnectTheDots = ({data}) => {
    return (
        <section id="connectthedots" className="w-full bg-white mx-auto relative overflow-hidden h-[60rem] lg:h-[74.938rem] xl:h-[48rem]">
            <div className="max-w-screen-xl mx-auto pl-8 pr-[4.813rem] pt-[3.5rem] md:pt-[5.563rem] pb-[8.25rem] md:pb-[7.25rem] md:pl-[12rem] md:pr-8 xl:pr-0 md:py-[6.75rem] ">
                <div className="w-full xl:w-4/12">
                    <div><h1 className="uppercase text-aqua">{data.heading}</h1></div>
                    <div className="mt-[1.75rem] md:mt-[2.063rem]"><h3>{data.subheading}</h3></div>
                    <div className="mt-[0.188rem] md:mt-[0.938rem] testimonial-author font-bold">{data.copy}</div>
                </div>
            </div>
            <div>
                <video id="cdtvideo" preload='auto' autoPlay muted loop playsInline className="absolute h-[37.5rem] lg:h-auto xl:h-[40rem] top-[18rem] md:top-[26rem] -right-[22rem] lg:top-[20rem] lg:-right-[27rem] xl:top-[3rem] xl:-right-[23rem] hidden md:block">
                    <source src={data.desktopVideo.mediaItemUrl} className="h-[37.5rem] lg:h-auto" type="video/mp4" />
                </video>
                <video id="cdtvideomobile" preload='auto' autoPlay muted loop playsInline className="absolute h-[37.5rem] lg:h-auto xl:h-[40rem] top-[18rem] md:top-[26rem] lg:top-[20rem]  xl:top-[3rem]  md:hidden">
                    <source src={data.mobileVideo.mediaItemUrl} className="h-[37.5rem] lg:h-auto" type="video/mp4" />
                </video>
                <div id="dottextOne" className="testimonial-author w-[14.125rem] lg:w-[16.938rem] text-right absolute top-[19.5rem] md:top-[27.5rem] lg:top-[20rem] xl:top-[2.5rem] right-[10rem] lg:right-[14rem] xl:right-[8.5rem] animftext">
                    <span>{data.firstLine}</span>
                </div>
                <div id="dottextTwo" className="testimonial-author w-[11.375rem] lg:w-[16.938rem] text-right absolute top-[24.5rem] md:top-[31.5rem] lg:top-[27rem] xl:top-[8.5rem] right-[15rem] lg:right-[21rem] xl:right-[14.5rem] animftext">
                    <span>{data.secondLine}</span>
                </div>
                <div id="dottextThree" className="testimonial-author w-[8.813rem] lg:w-[16.938rem] text-right absolute top-[29.5rem] md:top-[36.5rem] lg:top-[33rem] xl:top-[14rem] right-[18rem] lg:right-[25rem] xl:right-[17.5rem] animftext">
                    <span>{data.thirdLine}</span>
                </div>
                <div id="dottextFour" className="testimonial-author w-[7.375rem] lg:w-[13.25rem] text-right absolute top-[32.5rem] md:top-[39.5rem] lg:top-[39rem] xl:top-[19rem] right-[19.5rem] lg:right-[27rem] xl:right-[19rem] animftext">
                    <span>{data.fourthLine}</span>
                </div>
                <div id="dottextFive" className="testimonial-author w-[7.563rem] lg:w-[16.938rem] text-right absolute top-[39rem] md:top-[46rem] lg:top-[47.5rem] xl:top-[26rem] right-[20rem] lg:right-[27rem] xl:right-[19rem] animftext">
                    <span>{data.fifthLine}</span>
                </div>
                <div id="dottextSix" className="testimonial-author w-[8.806rem] lg:w-[16.938rem] text-right absolute top-[44.75rem] md:top-[51.75rem] lg:top-[54rem] xl:top-[31rem] right-[18.5rem] lg:right-[25rem] xl:right-[17rem] animftext">
                    <span>{data.sixthLine}</span>
                </div>
                <div id="dottextSeven" className="testimonial-author w-[12.375rem] lg:w-[19rem] text-right absolute top-[49.5rem] md:top-[56.5rem] lg:top-[61rem] xl:top-[36rem] right-[15rem] lg:right-[20.5rem] xl:right-[14rem] animftext">
                    <span>{data.seventhLine}</span>
                </div>
                <div id="dottextEight" className="testimonial-author w-[14.382rem] lg:w-[20rem] text-right absolute top-[54rem] md:top-[61rem] lg:top-[66rem] xl:top-[40rem] right-[9rem] lg:right-[12rem] xl:right-[8rem] animftext">
                    <span>{data.eightLine}</span>
                </div>
                <div id="dotsgraphic2" className="absolute top-[14.5rem] md:top-[21.5rem] lg:top-[13.5rem] xl:-top-[2rem] -right-[15rem] lg:-right-[19rem] h-[46.875rem] lg:h-[64.938rem] xl:h-[51rem] overflow-y-hidden">
                    <img id="dotsgraphic" src={OvalGraphic} className="m-0 max-h-[46.875rem] lg:max-h-none xl:h-[51rem]" alt="" />
                </div>
                
            </div>
        </section>
    )
}

export default ConnectTheDots;