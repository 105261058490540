import * as React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';

const Contact = ({ data }) => {
    const submitHandler = (values) => {
        /* Then create a new FormData obj */
        let formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("phone", values.phone);
        formData.append("message", values.message);

        fetch(
            'https://propelhealthforward.com/qwe23/sd2s.php',
            {
                method: 'POST',
                mode: "no-cors",
                headers: {
                    'Content-Type': 'form-data',
                    'Accept': '*/*'
                },
                body: formData
            },
        ).then((rs) => console.log("msg", rs)).catch((er) => console.log("er", er));


    };
    return (
        <section id="contact" className="w-full bg-pastelpink mx-auto" data-midnight="default">
            <div className="w-full max-w-screen-xl mx-auto pl-[1.313rem] pr-[3.875rem] md:px-[1.313rem] pb-[4.563rem] md:pb-0 flex flex-wrap justify-stretch relative md:min-h-[25.313rem] overflow-hidden">
                <video preload='auto' autoPlay muted loop playsInline className="w-full hidden md:block absolute top-0 md:-ml-[6rem] md:max-h-[15rem] md:mt-[5rem] lg:-ml-[9rem] lg:max-h-none lg:mt-auto xl:-ml-72 h-[25.313rem]">
                    <source src={data.desktopVideo.mediaItemUrl} type="video/mp4" />
                </video>
                <div className="w-full md:w-6/12 z-10">
                    <video preload='auto' autoPlay muted loop playsInline className="w-full md:hidden">
                        <source src={data.mobileVideo.mediaItemUrl} type="video/mp4" />
                    </video>
                </div>
                <div className="w-full md:w-6/12 text-right z-10 mt-[4.5rem] md:mt-24">
                    <h2 className="text-pastelpink border-black2 lsp3 uppercase" dangerouslySetInnerHTML={{ __html: data.title }}></h2>
                    <div className="text-right mt-[2.438rem] md:mt-[3.25rem]"><button id="connectBtn" className="button-contact">CONNECT</button></div>
                    <div id="contactForm" className="form mt-[2rem] md:mt-[3.438rem] md:pb-[4.75rem] hidden">
                        <Formik
                            initialValues={{ name: "", email: "", phone: "", message: "" }}
                            validate={(values) => {
                                const errors = {};
                                if (!values.name) {
                                    errors.name = "Required";
                                }

                                if (!values.email) {
                                    errors.email = "Required";
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = "Invalid email address";
                                }
                                if (!values.message) {
                                    errors.message = "Required";
                                }
                                if (values.phone !== "") {
                                    var phoneno = /^\d+$/;
                                    var phone = values.phone.match(phoneno);
                                    if (!phone) {
                                        errors.phone = "Invalid phone number";
                                    }
                                }
                                return errors;
                            }}
                            onSubmit={(values, { resetForm }) => {
                                submitHandler(values);
                                var form = document.getElementById("contactForm");
                                var tymsg = document.getElementById("tymsg");
                                form.style.display = 'none';
                                tymsg.style.display = 'block';
                                resetForm();

                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <div>
                                        <Field
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            className="form-contact-input"
                                        />
                                    </div>
                                    <ErrorMessage name="name" component="div" className="label-contact-input" />
                                    <div className="mt-[1.5rem]">
                                        <Field
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            className="form-contact-input"
                                        />
                                    </div>
                                    <ErrorMessage name="email" component="div" className="label-contact-input" />
                                    <div className="mt-[1.5rem]">
                                        <Field
                                            type="text"
                                            name="phone"
                                            placeholder="Phone Number"
                                            className="form-contact-input"
                                        />
                                    </div>
                                    <ErrorMessage name="phone" component="div" className="label-contact-input" />
                                    <div className="mt-[1.5rem]">
                                        <div className="text-left w-full">
                                            <label htmlFor="messageinput" className="label-contact-input">
                                                Message
                                            </label>
                                        </div>
                                        <Field
                                            component="textarea"
                                            name="message"
                                            className="mt-[1rem] border-2 form-contact-input txtarea"
                                        />
                                    </div>
                                    <ErrorMessage name="message" component="div" className="label-contact-input" />
                                    <div className="mt-[1.5rem] text-left flex">
                                        <button type="submit" disabled={isSubmitting} className="button-contact no-arrow cp cursor-pointer">
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div id="tymsg" className="md:mt-[3.438rem] md:pb-[4.75rem]" style={{ display: "none" }}>
                        <span className="font-henderson font-bold text-black text-[0.875rem]" dangerouslySetInnerHTML={{ __html: data.thankYouMessage }}></span>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Contact;