import * as React from "react";
const Authentic = ({data}) => {
    return (
        <section className="w-full bg-terracotta mx-auto" data-midnight="white">
            <div className="max-w-screen-xl mx-auto pl-8 pr-[4.813rem] pt-[3.5rem] pb-[4.063rem] md:pl-[12rem] md:pr-8 xl:pr-0 md:py-[6.75rem]">
                <div>
                    <h3 className="text-white">{data.firstHeading}</h3>
                </div>
                <div className="mt-[0.563rem] md:mt-[0.938rem]">
                    <h2 className="uppercase text-white">{data.secondHeading}</h2>
                </div>
            </div>
        </section>
    )
}

export default Authentic;