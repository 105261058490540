import * as React from "react";
import imgRight from "../../images/arrow-right.svg";
import imgLeft from "../../images/arrow-left.svg";
const HowWeDoIt = ({ data }) => {
    return (
        <>
            <section id="howwedoit" className="w-full overflow-x-hidden relative overflow-hidden" data-midnight="white">
                <div id="desktophowwedoitgap" className="hidden md:block absolute top-0 left-0 bg-black h-full w-[13.75rem] z-10"></div>
                <div id="howwedoititems" className="w-full flex justify-stretch cursor-arrows2">
                    <div className="self-stretch shrink-0 w-full md:w-[47.313rem] h-[28.625rem] md:h-[45.375rem] bg-black relative">
                        <div className="absolute top-[4.25rem] md:top-[7.438rem] left-[1.188rem] right-auto md:left-auto md:right-[8.875rem] max-w-[12.688rem] md:max-w-[19.063rem]">
                            <video preload='auto' autoPlay muted loop playsInline className="hidden md:block">
                                <source src={data.howWeRollVideoDesktop.mediaItemUrl} type="video/mp4" />
                            </video>
                            <video preload='auto' autoPlay muted loop playsInline className="md:hidden">
                                <source src={data.howWeRollVideoMobile.mediaItemUrl} type="video/mp4" />
                            </video>
                        </div>
                    </div>


                    <div className="self-stretch shrink-0 w-[38.68rem] md:w-[61.313rem] h-[28.625rem] md:h-[45.375rem] bg-babyblue2 md:bg-babyblue relative">
                        <div className="absolute bottom-0 right-0 h-full">
                            <video preload='auto' autoPlay muted loop playsInline className="h-full hidden md:block">
                                <source src={data.intentionalSection.desktopVideo.mediaItemUrl} type="video/mp4" />
                            </video>
                            <video preload='auto' autoPlay muted loop playsInline className="h-full md:hidden">
                                <source src={data.intentionalSection.mobileVideo.mediaItemUrl} type="video/mp4" />
                            </video>
                        </div>
                        <div className="absolute left-[2rem] md:left-[3.188rem] bottom-[4.375rem] md:bottom-[4rem] w-[9.438rem] md:w-[17.75rem] copy-how-we-roll" dangerouslySetInnerHTML={{ __html: data.intentionalSection.copy }}></div>
                        <div className="absolute left-[1.938rem] md:left-[3.875rem] top-[2.625rem] md:top-[3.688rem] w-[14.563rem] md:w-[30.75rem]">
                            <h2 className="text-babyblue border-black uppercase">{data.intentionalSection.title}</h2>
                        </div>
                    </div>



                    <div className="self-stretch shrink-0 w-[38.68rem] md:w-[61.313rem] h-[28.625rem] md:h-[45.375rem] bg-pastelyellow relative">
                        <div className="absolute w-[15.938rem] md:w-[25.25rem] top-[2.313rem] md:top-[3.688rem] right-[2rem] md:right-[4.5rem]">
                            <video preload='auto' autoPlay muted loop playsInline className="hidden md:block">
                                <source src={data.neverStartSection.desktopVideo.mediaItemUrl} type="video/mp4" />
                            </video>
                            <video preload='auto' autoPlay muted loop playsInline className="md:hidden">
                                <source src={data.neverStartSection.mobileVideo.mediaItemUrl} type="video/mp4" />
                            </video>
                        </div>
                        <div className="absolute left-[2.5rem] md:left-[3.938rem] top-[2.625rem] md:top-[3.563rem] w-[13rem] md:w-[20.438rem] copy-how-we-roll" dangerouslySetInnerHTML={{ __html: data.neverStartSection.copy }}></div>
                        <div className="absolute left-[1.938rem] md:left-[3.938rem] bottom-[2.625rem] md:bottom-[4rem] w-[31.063rem] md:w-[39.125rem]">
                            <h2 className="text-pastelyellow border-black uppercase">{data.neverStartSection.title}</h2>
                        </div>
                    </div>



                    <div className="self-stretch shrink-0 w-[38.68rem] md:w-[61.313rem] h-[28.625rem] md:h-[45.375rem] bg-pastelpink2 md:bg-pastelpink relative">
                        <div className="absolute bottom-14 md:bottom-0 right-0 w-[18.938rem] md:w-[30.313rem]">
                            <video preload='auto' autoPlay muted loop playsInline className="hidden md:block">
                                <source src={data.weMeetCustomersSection.desktopVideo.mediaItemUrl} type="video/mp4" />
                            </video>
                            <video preload='auto' autoPlay muted loop playsInline className="md:hidden">
                                <source src={data.weMeetCustomersSection.mobileVideo.mediaItemUrl} type="video/mp4" />
                            </video>
                        </div>
                        <div className="absolute left-[2.438rem] md:left-[3.875rem] bottom-[4.188rem] md:bottom-[3.375rem] w-[13.75rem] md:w-[20.438rem] copy-how-we-roll" dangerouslySetInnerHTML={{ __html: data.weMeetCustomersSection.copy }}></div>
                        <div className="absolute left-[2.438rem] md:left-[3.875rem] top-[2.625rem] md:top-[4.313rem] w-[25.625rem] md:w-[45.563rem]">
                            <h2 className="text-pastelpink border-black uppercase">{data.weMeetCustomersSection.title}</h2>
                        </div>
                    </div>



                    <div className="self-stretch shrink-0 w-[38.68rem] md:w-[61.313rem] h-[28.625rem] md:h-[45.375rem] bg-babyblue2 md:bg-babyblue relative">
                        <div className="absolute w-[16.567rem] md:w-[26.088rem] top-[2.25em] md:top-[3.563rem] right-[3.563rem] md:right-[3.938rem]">
                            <video preload='auto' autoPlay muted loop playsInline className="hidden md:block">
                                <source src={data.tectionSection.desktopVideo.mediaItemUrl} type="video/mp4" />
                            </video>
                            <video preload='auto' autoPlay muted loop playsInline className="md:hidden">
                                <source src={data.tectionSection.mobileVideo.mediaItemUrl} type="video/mp4" />
                            </video>
                        </div>
                        <div className="absolute left-[2.5rem] md:left-[3.938rem] top-[2.313rem] md:top-[3.688rem] w-[14.313rem] md:w-[21.938rem] copy-how-we-roll" dangerouslySetInnerHTML={{ __html: data.tectionSection.copy }}></div>
                        <div className="absolute left-[2.438rem] md:left-[3.938rem] bottom-[4.188rem] md:bottom-[3.375rem] w-[29.688rem] md:w-[49.375rem]">
                            <h2 className="text-babyblue border-black uppercase">{data.tectionSection.title}</h2>
                        </div>
                    </div>
                </div>
            </section>
            <div className="my-10 px-[1.313rem] md:pl-[14rem] flex items-center">
                <img id="prevhowwedoit" src={imgLeft} alt="" className="mr-5 cursor-pointer m-0" style={{ opacity: 0.5, height: "32px" }} />
                <img id="nexthowwedoit" src={imgRight} alt="" className="cursor-pointer m-0" style={{ height: "32px" }} />
            </div>
        </>
    )
}

export default HowWeDoIt;