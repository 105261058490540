import * as React from "react";
import footerLogo from "../../images/footer/pllogo.png";
import linkedinLogo from "../../images/footer/linkedin.svg";
import instaLogo from "../../images/footer/insta.svg";

const Footer = ({ data }) => {
    return (
        <section id="footer" className="w-full bg-black mx-auto h-[51.25rem] md:h-[38.938rem] overflow-hidden relative" data-midnight="white">
            <div className="absolute top-[15rem] md:top-[1.313rem] -right-10 md:-right-20 w-[18.125rem] md:w-[28.438rem] overflow-hidden">
                <video preload='auto' autoPlay muted loop playsInline className="hidden md:block">
                    <source src={data.desktopVideo.mediaItemUrl} type="video/mp4" />
                </video>
                <video preload='auto' autoPlay muted playsInline loop className="md:hidden">
                    <source src={data.mobileVideo.mediaItemUrl} type="video/mp4" />
                </video>
            </div>
            <div className="w-full max-w-screen-xl mx-auto px-[1.313rem] mt-[4.25rem] md:mt-[7.125rem] md:pl-[12rem] relative z-10">
                <div className="flex flex-wrap">

                    <div className="w-full md:w-[24rem]">
                        <div>
                            <img src={footerLogo} className="m-0" alt="" />
                        </div>
                        <div className="copy-footer mt-[1.25rem] md:mt-0" dangerouslySetInnerHTML={{ __html: data.copy }}>
                        </div>
                    </div>

                    <div className="ml-0 md:ml-[15.813rem] mt-[7.25rem] md:mt-[17.313rem]">
                        <div className="menu uppercase">FOLLOW US</div>
                        <div className="mt-[0.938rem] md:mt-[1.375rem] flex">
                            <div>
                                <a href="https://www.linkedin.com/company/propelhealthcommunications" target="_blank" rel="noreferrer" aria-label="LinkedIn Profile"><img src={linkedinLogo} alt="" /></a>
                            </div>
                            <div className="ml-[0.688rem]">
                                <a href="https://www.instagram.com/propelhealthforward/" target="_blank" rel="noreferrer" aria-label="Instagram Profile"><img src={instaLogo} alt="" /></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="w-full max-w-screen-xl mx-auto px-[1.313rem] md:mt-[3.313rem] relative z-10">
                <div className="flex flex-wrap place-items-end">

                    <div className="w-full mt-[1.25rem] md:mt-0 md:w-[35.375rem] md:pl-[12rem] copy-footer order-2 md:order-1 cprcopy" dangerouslySetInnerHTML={{ __html: data.copyright }}>
                    </div>

                    <div className="w-full mt-[9.75rem] md:mt-0 md:w-max md:ml-[4.438rem] order-1 md:order-2">
                        <div className="menu uppercase font-medium text-white">
                            {data.links.map((link, i) => {
                                return (<div key={i.toString()}><a href={link.url} aria-label={link.label} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{ __html: link.label }}></a></div>)
                            })}
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Footer;