import * as React from "react";
import linkedInLogo from "../../images/leadership/linkedinlogo.png";
import linkedInLogoMobile from "../../images/leadership/linkedinmobile.png";
import imgRight from "../../images/arrow-right.svg";
import imgLeft from "../../images/arrow-left.svg";
const Leadership = ({ data }) => {
    return (
        <section id="leadership" className="w-full bg-white overflow-hidden pt-[4.063rem] md:pt-[5.75rem] md:pl-[12rem] relative" data-midnight="default">
            <div id="desktopleadershipgap" className="hidden md:block absolute top-0 left-0 bg-white h-full w-[13.75rem] z-10"></div>
            <div className="w-full max-w-screen-xl mx-auto px-[1.313rem]">
                <h1 className="text-aqua m-0 uppercase">Not just <br className="md:hidden" />leaders,<br /> movers </h1>
            </div>

            <div id="leadershipitems" className="w-full cursor-arrows2  mt-[2.125rem] md:mt-[3.188rem]">
                <div className="w-full max-w-screen-xl mx-auto px-[1.313rem] flex justify-stretch">

                {data.map((leader, i) => {
                    return (<div className="self-stretch shrink-0 w-[18.25rem] md:w-[25.313rem] bg-black h-[26.063rem] md:h-[36.125rem] bg-black mr-[1.563rem] md:mr-[2rem] relative overflow-hidden" key={i.toString()}>
                        <div className="absolute top-0 left-0 w-full">
                            <img src={leader.photo.mediaItemUrl} className="w-full" alt="" />
                        </div>
                        <div className="absolute bottom-0 right-0 w-[14.5rem] md:w-[20.063rem] h-[4.875rem] md:h-[6.75rem] bg-neogreen">
                            <div className="flex items-center centerabs ml-[1.688rem] md:ml-[2.313rem] mr-[0.875rem] justify-between">
                                <div className="w-[8.938rem] md:w-[12.625rem] team-position mr-[1.063rem] md:mr-[1.688rem]" dangerouslySetInnerHTML={{ __html: leader.title }}>
                                </div>
                                <div className="flex items-center">
                                    <a href={leader.linkedInUrl} target="_blank" rel="noreferrer">
                                        <img src={linkedInLogo} alt="linkedin" className="m-0 hidden md:block" />
                                        <img src={linkedInLogoMobile} alt="linkedin" className="m-0 md:hidden" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>)
                })}
                </div>
            </div>

            <div className="my-10 px-[1.313rem] md:pl-[2rem] flex">
                <img id="prevleadership" src={imgLeft} alt="" className="mr-5 cursor-pointer m-0" style={{ opacity: 0.5, height: "32px" }} />
                <img id="nextleadership" src={imgRight} alt="" className="cursor-pointer m-0" style={{ height: "32px" }} />
            </div>
        </section>
    )
}

export default Leadership;